var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "regular-forms", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Forms", link: "components/forms" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3",
                  attrs: {
                    color: "success",
                    icon: "mdi-email",
                    title: "Stacked Form"
                  }
                },
                [
                  _c(
                    "v-form",
                    { staticClass: "mt-5" },
                    [
                      _c("v-text-field", { attrs: { label: "Email Address" } }),
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        attrs: { type: "password", label: "Password" }
                      }),
                      _c("v-checkbox", {
                        staticClass: "mt-0",
                        attrs: { label: "Subscribe to newsletter" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pl-0" },
                    [
                      _c(
                        "v-btn",
                        { attrs: { color: "success", "min-width": "100" } },
                        [_vm._v("\n            Submit\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3",
                  attrs: {
                    color: "success",
                    icon: "mdi-email-outline",
                    title: "Horizontal Form"
                  }
                },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", "no-wrap": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right grey--text",
                                  attrs: { cols: "3" }
                                },
                                [
                                  _vm._v(
                                    "\n                Email\n              "
                                  )
                                ]
                              ),
                              _c("v-col", [_c("v-text-field")], 1)
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "center", "no-wrap": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right grey--text",
                                  attrs: { cols: "3" }
                                },
                                [
                                  _vm._v(
                                    "\n                Password\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: { type: "password" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              attrs: {
                                align: "center",
                                "no-wrap": "",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c("v-col", { attrs: { cols: "3" } }),
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: { label: "Subscribe to newsletter" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pl-0",
                          attrs: { color: "success", "min-width": "100" }
                        },
                        [_vm._v("\n            Sign In\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3",
                  attrs: {
                    color: "success",
                    inline: "",
                    title: "Form Elements"
                  }
                },
                [
                  _c("v-subheader", { staticClass: "display-1 mt-3" }, [
                    _vm._v("\n          Text fields\n        ")
                  ]),
                  _c(
                    "v-form",
                    { staticClass: "pt-1" },
                    [
                      _c("v-text-field", {
                        staticClass: "ml-4",
                        attrs: { label: "With help", outlined: "" }
                      }),
                      _c("v-text-field", {
                        staticClass: "ml-4",
                        attrs: {
                          label: "Password",
                          outlined: "",
                          type: "password"
                        }
                      }),
                      _c("v-subheader", { staticClass: "display-1 mt-3" }, [
                        _vm._v("\n            Checkboxes\n          ")
                      ]),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 ml-4",
                                attrs: {
                                  "input-value": "true",
                                  label: "On",
                                  value: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 ml-4",
                                attrs: { label: "Off" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 ml-4",
                                attrs: {
                                  indeterminate: "",
                                  label: "Indeterminate",
                                  value: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 ml-4",
                                attrs: {
                                  disabled: "",
                                  "input-value": "true",
                                  label: "On Disabled"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 ml-4",
                                attrs: {
                                  disabled: "",
                                  label: "Off Disabled",
                                  value: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c(
                                "v-subheader",
                                { staticClass: "display-1 mt-3" },
                                [
                                  _vm._v(
                                    "\n                Radios\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "ml-4 mt-0",
                                  attrs: { column: "" }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Column Option 1",
                                      value: "radio-1"
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Column Option 2",
                                      value: "radio-2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c(
                                "v-subheader",
                                { staticClass: "display-1 mt-3" },
                                [
                                  _vm._v(
                                    "\n                Inline Radios\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "ml-4 mt-0",
                                  attrs: { row: "" }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Row Option 1",
                                      value: "radio-1"
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Row Option 2",
                                      value: "radio-2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-subheader", { staticClass: "display-1 mt-3" }, [
                        _vm._v("\n            Switches\n          ")
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "ml-4", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  "input-value": "true",
                                  label: "On",
                                  value: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [_c("v-switch", { attrs: { label: "Off" } })],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  disabled: "",
                                  "input-value": "true",
                                  label: "On Disabled",
                                  value: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-switch", {
                                attrs: { disabled: "", label: "Disabled" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  "input-value": "true",
                                  label: "Loading",
                                  loading: "primary",
                                  value: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3",
                  attrs: {
                    color: "success",
                    inline: "",
                    title: "Input Variants"
                  }
                },
                [
                  _c("v-subheader", { staticClass: "display-1 mt-3" }, [
                    _vm._v("\n          Selects\n        ")
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "ml-1", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-select", {
                            attrs: { items: _vm.items, label: "Standard" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.items,
                              filled: "",
                              label: "Filled style"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.items,
                              label: "Outlined style",
                              outlined: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.items,
                              label: "Solo field",
                              solo: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-subheader", { staticClass: "display-1 mt-3" }, [
                    _vm._v("\n          Sliders\n        ")
                  ]),
                  _c("v-slider", {
                    staticClass: "ml-4",
                    attrs: {
                      color: "secondary",
                      "prepend-icon": "mdi-volume-plus",
                      "track-color": "secondary lighten-3"
                    }
                  }),
                  _c("v-slider", {
                    staticClass: "ml-4",
                    attrs: { "append-icon": "mdi-alarm" }
                  }),
                  _c("v-slider", {
                    staticClass: "ml-4",
                    attrs: {
                      "append-icon": "mdi-magnify-plus-outline",
                      "prepend-icon": "mdi-magnify-minus-outline"
                    },
                    on: {
                      "click:append": _vm.zoomIn,
                      "click:prepend": _vm.zoomOut
                    },
                    model: {
                      value: _vm.zoom,
                      callback: function($$v) {
                        _vm.zoom = $$v
                      },
                      expression: "zoom"
                    }
                  }),
                  _c("v-subheader", { staticClass: "display-1 mt-3" }, [
                    _vm._v("\n          Textareas\n        ")
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "ml-1" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              name: "input-7-1",
                              label: "Default style",
                              value:
                                "The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through.",
                              hint: "Hint text"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              solo: "",
                              name: "input-7-4",
                              label: "Solo textarea"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              filled: "",
                              name: "input-7-4",
                              label: "Filled textarea",
                              value:
                                "The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              name: "input-7-4",
                              label: "Outlined textarea",
                              value:
                                "The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }